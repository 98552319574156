import React from 'react'
import { cn } from '../../lib/helpers'
import * as styles from './Button.module.css'
import PropTypes from 'prop-types'

export const OutlinedButton = ({ onClick, buttonText, small }) => {
  
  return (
    <button onClick={onClick} className={small ? styles.outlinedButtonSmall : styles.outlinedButton}>
      {buttonText}
    </button>
  )
}

OutlinedButton.propTypes = {
  className: PropTypes.string,
  buttonText: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}
