import React from 'react'
import SEO from '../components/seo'
import * as styles from '../styles/pages.module.css'
import Layout from '../components/layout'
import Container from '../components/container'
import { OutlinedButton } from '../components/Button/OutlinedButton'
import { Button } from '../components/Button/Button'
import { navigate } from 'gatsby'

const NotFoundPage = () => (


  <Layout  backgroundColor='#FFF7F5' >
    <SEO title='404: Not found' />
    <Container backgroundColor='#FFF7F5' wide>
      <div className={styles.box404}>
      <h1 className={styles.title404}>Whoops, something went wrong</h1>
        <p className={styles.subtitle404}>The page you are looking for can't be found..</p>
        <div className={styles.button404}>
          <OutlinedButton buttonText='←  Go back home' onClick={() => navigate('/') } />
          <Button color buttonText='Contact us' onClick={() => navigate('/contact') }  />
                   
        </div>
      </div>
</Container>
  </Layout>
)

export default NotFoundPage
